<template>
  <b-container>
    <b-row>
    <b-col lg='12'>
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">Issues</h4>
        </template>
        <template v-slot:headerAction>
          <b-button v-b-modal.createIssue variant="primary">Add Issues</b-button>
          <b-modal id="createIssue"  size="lg" title="Create Issue" hide-footer no-close-on-backdrop>
            <b-container fluid>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(createNewIssue)">
                  <b-row>
                    <b-col lg="12">
                      <iq-card>
                        <template v-slot:body>
                          <div class="new-user-info">
                            <b-row>
                              <b-form-group class="col-md-6" label="Module:" label-for="code">
                                <ValidationProvider name="Module" ref="Module" rules="required" v-slot="{ errors }">
                                  <b-form-input type="text" placeholder="Module" :class="(errors.length >
                                  0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>
                              <b-form-group class="col-md-6" label="Sub Module:" label-for="code">
                                <ValidationProvider name="subModule" ref="subModule" rules="required" v-slot="{ errors }">
                                  <b-form-input type="text" placeholder="subModule" :class="(errors.length >
                                  0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>
                              <b-form-group label="Issue" label-for="Issue" class="col-md-12">
                                <b-form-textarea id="Issue" rows="2" ></b-form-textarea>
                              </b-form-group>
                            </b-row>
                            <div class="d-flex  mt-4 align-items-center justify-content-end">
                              <b-button variant="primary" type="submit" >Create Issue</b-button>
                            </div>
                          </div>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </form>
              </ValidationObserver>
            </b-container>
          </b-modal>
        </template>
        <template v-slot:body>
          <div v-if="loadTable" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading...
          </div>
          <b-table v-else striped responsive :fields="columns" :items="getAllProject" class="mb-0 table-borderless">
          </b-table>
          <b-pagination v-model="currentPage"
                        class="mt-3"
                        :total-rows="total"
                        align="right"
                        :per-page="per_page"
                        aria-controls="my-table"
                        @input="changeContent"
          ></b-pagination>
        </template>
      </iq-card>
    </b-col>
  </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'issues',
  data () {
    return {
      currentPage: 1,
      per_page: 10,
      total: 6,
      loadingOpenAdd: false,
      loadTable: true,
      columns: [
        { label: 'Title', key: 'en_title', class: 'text-left' },
        { label: 'Ar Title', key: 'ar_title', class: 'text-left' },
        { label: 'Code', key: 'code', class: 'text-left' },
        { label: 'Enterprise', key: 'enterprise', class: 'text-left' },
        { label: 'Color', key: 'color', class: 'text-left' },
        { label: 'Sort', key: 'sort', class: 'text-left' }
      ],
      getAllProject: []
    }
  },
  computed: {
  },
  methods: {
    createNewIssue () {
      console.log('hiiiiiiii')
    }
  },
  components: {
  },
  mounted () {
    core.index()
  },
  created () {
  }
}
</script>
<style scoped>
.colorSpan{
  width: 20px;
  height: 20px;
}
th{
  white-space: pre !important;
}
td{
  white-space: pre !important;
}
</style>
